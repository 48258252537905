.ContactForm {
  display: block;
  width: 100%;
  font-weight: 700;

  & > .heading {
    display: block;
  }

  & > a {
    display: inline-block;
    font-size: 0.8rem;
    margin: 12px 0;
  }

  & > p {

    & > .emailInput {
      display: flex;
      flex-direction: row;
      padding: 16px 0;
      box-sizing: border-box;

      & > input {
        flex: 1;
        background-color: white;
        border-radius: 24px;
        max-width: max(300px, calc(25vw - 40px));
        width: 70%;
        height: 40px;
        padding: 0 16px;
        border: none;
        margin-right: 8px;
      }

      & > button {
        border-radius: 32px;
        padding: 8px 16px;
        text-transform: none;
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 0.8rem;
        --button-text-color-disabled: black;

        
        & .signupIcon {
          display: none;
        }

        .smallScreen & {
          padding: 8px;
          min-width: 0;

          & .signupText {
            display: none;
          }

          & .signupIcon {
            display: inline-block;
          }
        }
      }
    }
  }
}
