.SocialLinks {
  text-align: start;
  & svg {
    fill: white;
    padding: 4px;
    transition: fill var(--transition-color);

    &.instagram:hover {
      fill: #c13584;
    }
    &.twitter:hover {
      fill: #1da1f1;
    }
    &.discord:hover {
      fill: #5765f2;
    }
    &.telegram:hover {
      fill: #229ed9;
    }
  }

}
