.AnnouncementBanner {
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  height: 48px;
  flex-direction: row;
  padding: 8px var(--side-padding);
  box-sizing: border-box;
  align-content: center;
  justify-content: space-between;
  color: white;
  text-align: center;
  gap: 12px;
  // text-transform: uppercase;

  &.AnnouncementShowButtonWhenClosed {
    justify-content: center;
  }

  &.AnnouncementClosed {
    justify-content: center;

    // &.AnnouncementShowButtonWhenClosed {
    //   justify-content: space-between;
    // }
  }

  // --button-bg-color-white: rgba(255, 255, 255, 0.8);

  & > .AnnouncementButtons > a {
    line-height: 1rem;
    margin-left: 6px;
    transition: background-color var(--transition-color) !important;

    &:hover {
      color: black;
    }

    & button {
      height: 32px;

      &:hover {
        color: white;
        background-color: black;
      }
    }

    .smallScreen & {
      font-size: 0.7rem;

      @media only screen and (max-width: 350px) {
        font-size: 0.5rem;
      }
    }
  }

  & > .AnnouncementContentWrapper {
    display: flex;
    flex-direction: row;
  }

  & > .AnnouncementContent,
  & > .AnnouncementContentWrapper > .AnnouncementContent {
    margin: auto 0;
    padding-right: 4px;
    font-size: 0.85rem;

    // .smallScreen & {
    //   font-size: 0.7rem;

    //   @media only screen and (max-width: 350px) {
    //     font-size: 0.5rem;
    //   }
    // }
  }

  // background: linear-gradient(124deg, #E50000, #FF8D00, #FFEE00, #028121, #004CFF, #760088, #613915, #73D7EE, #FFAFC7);
  background: linear-gradient(124deg, #4589FF, #FF7EB6, #F1C21B, #49CD7A, #4589FF);
  // background: conic-gradient(from 90deg at 50% 51.52%, #4589FF 0deg, #FF7EB6 141.23deg, #F1C21B 231.23deg, #49CD7A 287.48deg, #4589FF 360deg);
  background-size: 1800% 1800%;

  -webkit-animation: rainbow 18s ease infinite;
  -z-animation: rainbow 18s ease infinite;
  -o-animation: rainbow 18s ease infinite;
  animation: rainbow 18s ease infinite;
}
