.app {
  height: fit-content;
  display: flex;
  flex-direction: column;

  & > .content {
    flex: 1;
  }
  --side-padding: 48px;
  --menu-height: 54px;
  --menu-scroll-offset: -54px;
}
.smallScreen {
  --side-padding: 24px;
}


.linkTarget {
  display: block;
  height: 0px;
  top: var(--menu-scroll-offset);
  position: absolute;
}
