header {
  position: relative;
  background-color: var(--color-header-background);
  align-items: center;
  justify-content: space-between;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 50;

  &.announcement {
    margin-top: 48px;
  }
}

.AppLogoLink {
  height: var(--menu-height);
}
.app-logo {
  height: 120px;
  width: 120px;
  position: absolute;
  left: 24px;
  top: 24px;
}

.topSection {
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.topSectionWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
}

.buttonRsvp {
  border-radius: 32px !important;
  padding: 8px 24px !important;
  margin-top: 24px !important;
}

.tagline {
  text-align: center;
  padding: auto;
  margin: auto;
  flex-grow: 1;

  padding: 0 150px;
}

.menuContainer {
  width: 100%;
  height: var(--menu-height);
}

.menu {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: space-between;
  border-top: rgba(0, 0, 0, 0.2) 1px solid;
  border-bottom: rgba(0, 0, 0, 0.2) 1px solid;
  background: var(--color-background);
  box-sizing: border-box;
  // letter-spacing: 0.1rem;

  & .app-logo-sticky {
    opacity: 0;
    transition: opacity 0.2s ease-out;
    height: var(--menu-height);
    width: var(--menu-height);
    align-self: center;
    padding: 4px;
    box-sizing: border-box;
  }
  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;

    & .app-logo-sticky {
      opacity: 1;
    }
  }

  &.announcement {
    top: 48px;
  }
}

.MenuItem {
  margin: -1px 0;
  padding: 0 16px;
  position: relative;
  transition: background-color 150ms linear;
  white-space: nowrap;
  letter-spacing: 0.1rem;

  & > a {
    position: relative;
    display: block;
    line-height: var(--menu-height);
    font-size: 0.8rem;
    font-weight: 700;
    z-index: 10;
    color: black;
  }

  &:hover {
    background-color: var(--secondary-color-accent);
    color: white;
    a {
      color: white;
    }
  }
}

.MenuSide {
  display: flex;
  padding: 0 48px;
  justify-content: flex-end;
  align-items: center;
  &.Left {
    justify-content: flex-start;
  }

  .MenuItem {
    margin-right: 6px;

    &:hover {
      background-color: var(--theme-button-sales-zone-bg);
    }
  }
}

.cta-link {
  width: var(--menu-height);
  height: var(--menu-height);
  margin: 0 8px;
  vertical-align: bottom;
}

header {
  &.smallScreen {
    --side-padding: 24px;
    min-height: fit-content;
    display: block;
    & .app-logo {
      display: none;
    }
    .MenuSide {
      padding: 0 16px;
    }
    .tagline {
      margin: var(--menu-height) 0 0;
      padding: 32px 16px;
      font-size: 1.2rem;
    }
    .menuContainer {
      height: 0;
      position: fixed;
    }
    .buttonRsvp {
      margin-top: 0 !important;
      margin-bottom: 24px !important;
    }

    .menu {
      &.sticky {
        padding: 4px 0;
      }
    }
  }
}

#menuToggle {
  display: block;
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  width: 48px;
  height: 48px;

  & > .hamburger,
  & > .hamburgerClose {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    &.announcement {
      z-index: 5;
    }
  }

  & > .hamburgerClose {
    display: none;
  }

  & > input {
    position: relative;
    display: block;
    cursor: pointer;
    opacity: 0;
    z-index: 150;
    -webkit-touch-callout: none;
    height: 100%;
    width: 100%;
    margin: 0;
  }

  & input:checked {
    & ~ #menu {
      transform: none;
    }
    & ~ .hamburger {
      display: none;
    }
    & ~ .hamburgerClose {
      display: block;
    }
  }
}

#menu {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: calc(var(--menu-height) + 8px);
  right: 0;
  bottom: 0;
  flex-direction: column;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translateX(100vw);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  background-color: rgba(0, 0, 0, 0.9);
  padding: 16px 0;
  pointer-events: all;
  color: white;

  &.announcement {
    top: 48px;
  }

  .menuFooter {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;

    & .Disclaimers {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
    }
  }

  & .MenuItem {
    text-align: start;
    width: 100%;

    & > a {
      font-family: var(--font-header);
      font-size: 1.2rem;
      color: white;
    }
  }
}

.mobileWallet {
  display: block;
  width: fit-content;
  height: fit-content;
  margin: 12px;
}

.header-sales-button {
  min-width: 132px !important;
  height: 48px;
  font-weight: 700 !important;

  &.smallscreen {
    height: 36px;
    margin-right: 8px;
  }
}

.rainbowLink {
  & > .LinkText {
    // background: linear-gradient(
    //   to right,
    //   #ef5350,
    //   #f48fb1,
    //   #7e57c2,
    //   #2196f3,
    //   #26c6da,
    //   #43a047,
    //   #eeff41,
    //   #f9a825,
    //   #ff5722
    // );
    // background: conic-gradient(from 90deg at 50% 51.52%, #4589FF 0deg, #FF7EB6 141.23deg, #F1C21B 231.23deg, #49CD7A 287.48deg, #4589FF 360deg);
    background: linear-gradient(124deg, #4589FF, #FF7EB6, #F1C21B, #49CD7A, #4589FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;

    -webkit-animation: rainbow 8s ease infinite;
    -z-animation: rainbow 8s ease infinite;
    -o-animation: rainbow 8s ease infinite;
    animation: rainbow 8s ease infinite;
  }

  &:hover > .LinkText {
    background: linear-gradient(124deg, #dce7f8, #ffffff, #fff5d1, #d7f8e3, #e1ecff);
    background-clip: text;
    animation: hue 3s ease infinite;
  }
}

.MenuItem:hover > .rainbowLink > .LinkText {
  background: linear-gradient(124deg, #dce7f8, #ffffff, #fff5d1, #d7f8e3, #e1ecff);
  background-clip: text;
}

@-webkit-keyframes rainbow {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-moz-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@-o-keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}





@-webkit-keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(-1turn);
  }
}
@-moz-keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(-1turn);
  }
}
@-o-keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(-1turn);
  }
}
@keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(-1turn);
  }
}