footer {
  color: var(--color-footer-text);
  padding: 32px var(--side-padding);
  position: relative;

  & > .starAnimation {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: -1;
    background-color: var(--color-footer-background);

    & > svg {
      height: 98.5% !important;
      transform: translate3d(0px, 4px, 0px) !important;
    }
  }

  .LinkList {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .smallScreen & {
      flex-direction: column;

      & > div {
        width: auto;
        padding: 8px 0;
      }
    }

    & > div {
      width: 25vw;
    }

    & .heading {
      display: block;
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 1rem;
    }
  }
}
