html {
  /* CSS custom property for the polyfill */
  --scroll-behavior: smooth;
  scroll-behavior: smooth;
}
.web3modal-modal-lightbox {
  z-index: 100 !important;
}
body {
  left: 0;
  right: 0;

  --mobile-min-width: 1150px;
  --font: 'Montserrat';
  --font-header: 'Monument Extended';
  font-weight: 700;

  // THEME
  // --theme-background-color: 101, 44, 144;
  // --theme-background-color-accent: 136 73 184;
  --theme-background-color: 105, 6, 104;
  --theme-background-color-accent: 151 26 148;
  --theme-background-color-highlight: #fb85ff;
  --theme-background-color-accent-highlight: rgb(249, 255, 167);
  --theme-text-color: white;
  --theme-prestige-level: white;
  --theme-prime-level: #f8d9df;
  --theme-plus-level: #cd80b2;
  --theme-button-sales-zone-color: white;
  --theme-button-sales-zone-bg: var(--theme-background-color-highlight);
  --theme-link-color-hover: var(--theme-background-color-accent-highlight);
  --theme-link-color-dark-hover: var(--tertiary-color-hover);
  // --

  --primary-color: white;
  --primary-color-alpha: rgb(14 0 26 / 71%);
  --primary-color-varient: rgb(32 6 54);
  --primary-color-varient-alpha: rgb(32 6 54 / 71%);
  --secondary-color: rgb(var(--theme-background-color));
  --secondary-color-alpha: rgba(var(--theme-background-color), 0.9);
  --secondary-color-alpha2: rgba(var(--theme-background-color), 0.8);
  --secondary-color-hover: rgba(--theme-background-color, 0.8);
  --secondary-color-accent: rgb(var(--theme-background-color-accent));
  --secondary-color-accent-alpha: rgba(var(--theme-background-color-accent), 0.9);
  --tertiary-color: #d91c5c;
  --tertiary-color-hover: #ff0059;
  --quaternary-color: rgb(112, 86, 189);
  --quaternary-color-hover: rgb(3, 2, 3);

  --divider-color: #e6e6e6;

  --color-text: black;
  --color-header-text: #202020;
  --color-header-background: transparent;
  --color-footer-text: var(--theme-text-color);
  --color-footer-background: black;
  --color-background: #ffffff;
  --color-placeholder: #efebeb;
  --app-spacing: 16px;

  --transition-color: 250ms cubic-bezier(0.4, 0, 0.2, 1);
  --transition-opacity: 250ms cubic-bezier(0.4, 0, 0.2, 1);

  --menu-button-bg-color: var(--primary-color-varient-alpha);
  --menu-button-bg-shadow: 0 0 20px var(--primary-color-alpha);

  --button-bg-color-disabled: rgba(204, 204, 204, 0.27);
  --button-bg-color-grey: rgb(66, 66, 66);
  --button-bg-color-blue: rgb(89, 108, 187);
  --button-bg-color-blue-flat: rgba(21, 61, 111, 0.44);
  --button-bg-color-dark: rgb(40 44 51);
  --button-bg-color-green: rgb(27 183 107);
  --button-bg-color-pink: rgb(237 109 216);
  --button-bg-color-dark-pink: rgb(237 109 216);
  --button-bg-color-yellow: rgb(244 240 212);
  --button-bg-color-black: rgb(0, 0, 0);
  --button-bg-color-white: white;

  --button-bg-color-hover-grey: rgb(100, 100, 100);
  --button-bg-color-hover-blue: rgb(98, 126, 234);
  --button-bg-color-hover-blue-flat: rgba(21, 61, 111, 0.44);
  --button-bg-color-hover-dark: rgb(40 44 51);
  --button-bg-color-hover-green: rgb(15 157 88);
  --button-bg-color-hover-pink: rgb(247 126 227);
  --button-bg-color-hover-dark-pink: rgb(247 126 227);
  --button-bg-color-hover-yellow: rgb(255 242 212);
  --button-bg-color-hover-black: rgb(73, 172, 248);
  --button-bg-color-hover-white: rgb(249, 255, 167);

  --button-text-color-disabled: rgba(120, 120, 120, 0.8);
  --button-text-color-grey: white;
  --button-text-color-blue: white;
  --button-text-color-blue-flat: rgb(109, 168, 255);
  --button-text-color-dark: rgb(204 204 204);
  --button-text-color-green: white;
  --button-text-color-pink: white;
  --button-text-color-dark-pink: white;
  --button-text-color-yellow: var(--primary-color);
  --button-text-color-black: white;
  --button-text-color-white: rgb(0, 0, 0);

  --button-border-color-grey: '';
  --button-border-color-blue: '';
  --button-border-color-blue-flat: 1px solid rgba(21, 61, 111, 0.44);
  --button-border-color-dark: solid 1px rgb(60 62 66);
  --button-border-color-green: '';
  --button-border-color-pink: '';
  --button-border-color-dark-pink: solid 1px rgb(220 125 138);
  --button-border-color-yellow: '';
  --button-border-color-black: '';
  --button-border-color-white: '';

  --button-border-color-hover-grey: '';
  --button-border-color-hover-blue: '';
  --button-border-color-hover-blue-flat: rgb(33, 114, 229);
  --button-border-color-hover-dark: rgb(156 173 181) !important;
  --button-border-color-hover-green: '';
  --button-border-color-hover-pink: '';
  --button-border-color-hover-dark-pink: rgb(247 126 227) !important;
  --button-border-color-hover-yellow: '';
  --button-border-color-hover-black: '';
  --button-border-color-hover-white: '';

  --button-svg-color-grey: white;
  --button-svg-color-blue: white;
  --button-svg-color-blue-flat: white;
  --button-svg-color-dark: white;
  --button-svg-color-green: white;
  --button-svg-color-pink: white;
  --button-svg-color-dark-pink: white;
  --button-svg-color-yellow: var(--primary-color);
  --button-svg-color-black: white;
  --button-svg-color-white: black;

  --button-shadow-color-disabled: inset 0px 0px 5px rgb(208 208 208);

  --wallet-button-background-color: black;
  --wallet-button-text-color: white;
  --wallet-balance-text-color: white;
  --wallet-balance-bg-color: rgb(80, 80, 80);
  --wallet-balance-bg-color-hover: rgb(50, 50, 50);

  --wallet-main-network-bg-color: rgb(41 182 175 / 20%);
  --wallet-main-network-text-color: rgb(41, 182, 175);

  --wallet-test-network-bg-color: var(--secondary-color-alpha);
  --wallet-test-network-text-color: var(--primary-color);

  --wallet-block-number-text-color: rgb(247 201 208);

  --wallet-nft-bg-color-blue: linear-gradient(0deg, rgb(187 187 255) -25%, rgb(89 108 187) 15%, rgb(169 179 218) 100%);
  --wallet-nft-bg-color-green-flat: rgb(68 152 98 / 8%);
  --wallet-nft-bg-color-white: rgb(231 207 211 / 25%);
  --wallet-nft-bg-color-yellow: var(--secondary-color);
  --wallet-nft-bg-color-black: var(--secondary-color);

  --wallet-nft-bg-color-blue-hover: '';
  --wallet-nft-bg-color-green-flat-hover: '';
  --wallet-nft-bg-color-white-hover: rgb(241 226 229 / 30%);
  --wallet-nft-bg-color-yellow-hover: var(--secondary-hover-color);
  --wallet-nft-bg-color-black-hover: var(--secondary-hover-color);

  --wallet-nft-color-blue: rgb(235, 239, 255);
  --wallet-nft-color-green-flat: rgb(168 183 180 / 75%);
  --wallet-nft-color-white: white;
  --wallet-nft-color-yellow: var(--primary-color);
  --wallet-nft-color-black: var(--primary-color);

  --wallet-nft-border-color-blue: '';
  --wallet-nft-border-color-green-flat: solid 1px rgb(105 117 115 / 59%);
  --wallet-nft-border-color-white: '';
  --wallet-nft-border-color-yellow: '';
  --wallet-nft-border-color-black: '';

  margin: 0;
  padding: 0;
  font-family: var(--font);
  background-color: var(--color-background);
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-header);
  letter-spacing: 2px;
}

h1 {
  font-size: 2.6rem;
  margin: 24px 0;
}

p {
  margin: 12px 0;
}

a {
  color: var(--tertiary-color);
  transition: color var(--transition-color) !important;
  text-decoration: none !important;
}

a:hover {
  color: var(--theme-link-color-hover);
}

a.link-dark:hover {
  color: var(--theme-link-color-dark-hover);
}

a.link-light {
  color: white;
  text-decoration: underline !important;

  &:hover {
    color: var(--theme-link-color-hover);
  }
}


//   /* width */
// ::-webkit-scrollbar {
//   width: 8px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: var(--primary-color-varient-alpha);
//   box-shadow: inset 0 0 5px var(--primary-color-varient);
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: var(--secondary-color);
//   box-shadow: inset 0 0 5px var(--primary-color-varient-alpha);
//   border-radius: 10px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: var(--secondary-color-hover);
// }
.Spacer {
  flex-grow: 1;
  flex-shrink: 1;
}

@font-face {
  font-family: 'Monument Extended';
  src: local('Monument Extended'), url('./assets/MonumentExtended-Regular.otf') format('opentype');
}
