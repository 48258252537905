.provenance {
  background-color: var(--body-color);
  font-size: var(--body-font-size);

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 80%;
  margin-left: auto;
  margin-right: auto;

  h1 {
    padding: 0 1rem;
    text-align: center;
    margin-top: 0.6rem;
    margin-bottom: 0.3rem;
  }

  p {
    text-align: justify;
  }
}
