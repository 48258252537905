.tick {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 0.4rem;
  white-space: nowrap;

  @media only screen and (max-width: 471px) {
    font-size: 0.275rem !important;
  }
}

.tick-flip,
.tick-text-inline {
  font-size: 2.5em;
}

.tick-label {
  margin-top: 1em;
  font-size: 1em;
}

.tick-char {
  width: 1.5em;
}

.tick-text-inline {
  display: inline-block;
  text-align: center;
  min-width: 1em;
}

.tick-text-inline + .tick-text-inline {
  margin-left: -0.325em;
}

.tick-group {
  display: flex;
  margin: 0 0.5em;
  text-align: center;
  text-transform: none;
}

.tick-text-inline {
  font-size: 0.85rem;
  color: white !important;
  align-self: center;
  padding-left: 3px;

  @media only screen and (max-width: 1170px) {
    font-size: 0.7rem;
  }

  @media only screen and (max-width: 350px) {
    font-size: 0.5rem;
  }
}

.tick-label {
  color: #595d63 !important;
}

.tick-flip-panel {
  color: #3c3e3c !important;
  background-color: white !important;
}

.tick-flip-panel-text-wrapper {
  line-height: 1.45 !important;
}

.tick-flip {
  border-radius: 0.12em !important;
  width: 20px;
}
