.modal
  display: flex
  align-items: center
  justify-content: center
  z-index: 20

  &-wrapper
    outline: none

  &-list-item:hover
    background-color: rgb(220 220 220 / 4%)

  &-list-item > div
    margin-left: 8px
